import React from 'react';
import '../../App.css'

import Sidebar from './sidebar';

const Seccionales = () => {
    return (
        <div className='ins'>
            <Sidebar />
            <div className='col-lg-9'>
                <div className='all'>

                    <div className="page-title">
                        <h1>SECCIONALES</h1>
                    </div>
                    <div className="page-content clearfix">
                        <div classname="col-md-12 div_descripcion_default alto_contraste" line-height="20px" >
                            <div className='open-map'>
                                <h1>hola</h1>
                                
                            </div>  <br />
                        </div>
                        <br />
                    </div>
                    <br />
                </div>
                <br />
            </div>
        </div>



    );
};

export default Seccionales;
