import React from 'react';


function View() {
    return (
        <div style={{textAlign: 'center'}}>
            <br />
           <a href="./pdf" target="_blank"> <button>Ir a PDF</button></a>
        </div>
    );
}

export default View;